<template>
  <form>
    <v-text-field
      v-model="name"
      :error-messages="nameErrors"
      :counter="10"
      label="Nombre Producto"
      required
      @input="$v.name.$touch()"
      @blur="$v.name.$touch()"
    ></v-text-field>
    <!--<v-text-field
      v-model="email"
      label="Descripción Producto"
      @input="$v.email.$touch()"
      @blur="$v.email.$touch()"
    ></v-text-field>-->
    <quill-editor
      v-model="email"
      label="Descripción Producto"
      @input="$v.email.$touch()"
      @blur="$v.email.$touch()"
      class="mb-4"
      :options="editorOption"
    />
     <v-btn
      class="mr-4"
      @click="submit"
    >
      Guardar
    </v-btn>
    <v-btn @click="clear">
      Limpiar
    </v-btn>
  </form>
</template>
<script>
  import { validationMixin } from 'vuelidate'
  import { required, maxLength } from 'vuelidate/lib/validators'

  export default {
    mixins: [validationMixin],

    validations: {
      name: { required, maxLength: maxLength(50) },
    },
    data(){
        return{
            name: '',
            email: '',
            id : this.$route.params.id,
            editorOption: {
              placeholder: 'Ingrese una descripción...',
            }
        }
    },

    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
        !this.$v.name.required && errors.push('Name is required.')
        return errors
      },
    },

    methods: {
      submit () {
        this.$http.post('products',{
            data: {
              attributes: {
                architecture_tag_id: this.id,
                name: this.name,
                description: this.email,
              },
            },
            },{headers:{Authorization: "Bearer " + this.getCrypto()}}
            ).then(response => {
        this.Products = response.data
        }).catch(error => {
            console.error(error)
        })
        this.$router.go(-2)
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.email = ''
        this.select = null
        this.checkbox = false
        
      },
    },
  }
</script>